import React from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';

export default function Page3() {
  const images = useStaticQuery(graphql`
    query {
      feature1: file(relativePath: { eq: "landing/feature1.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature2: file(relativePath: { eq: "landing/feature2.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature3: file(relativePath: { eq: "landing/feature3.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const data = [
    {
      title: 'easyToCreateTestScript',
      description: 'easyToCreateTestScriptDescription',
      src: images?.feature1?.childImageSharp?.fluid?.src,
    },
    {
      title: 'easyToScheduleToExecutionContinuouslySpecificTestCases',
      description:
        'easyToScheduleToExecutionContinuouslySpecificTestCasesDescription',
      src: images?.feature2?.childImageSharp?.fluid?.src,
    },
    {
      title: 'easyToSetUpMobileDevicesToRunTestCases',
      description: 'easyToSetUpMobileDevicesToRunTestCasesDescription',
      src: images?.feature3?.childImageSharp?.fluid?.src,
    },
  ];

  const { t } = useTranslation();
  const children = data.map((card, i) => (
    <Col key={i.toString()} md={8} xs={24}>
      <img src={card.src} alt="" width="240" height="240" />
      <h5>{t(card.title)}</h5>
      <div className="detail">{t(card.description)}</div>
    </Col>
  ));
  return (
    <OverPack component="section" className="page-wrapper page3 text-center">
      <QueueAnim
        type="bottom"
        className="page text-center"
        leaveReverse
        key="page"
      >
        <h2 key="title">{t('features')}</h2>
        <span key="line" className="separator" />
        <QueueAnim
          type="bottom"
          // className="page"
          leaveReverse
          key="page3"
          component={Row}
        >
          {children}
        </QueueAnim>
      </QueueAnim>
    </OverPack>
  );
}
