import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import { Button } from 'antd';
import { Element } from 'rc-scroll-anim';
import { useI18next, Trans } from 'gatsby-plugin-react-i18next';
import BannerImage from './BannerImage';

function Banner({ className, isMobile, navToShadow }) {
  const { navigate, t } = useI18next();

  const handleGetStarted = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  return (
    <Element
      component="section"
      className={`${className}-wrapper page`}
      onChange={navToShadow}
    >
      <div className={className}>
        <div className={`${className}-img-wrapper`}>
          <BannerImage />
        </div>
        <QueueAnim
          type={isMobile ? 'bottom' : 'right'}
          className={`${className}-text-wrapper`}
          delay={300}
        >
          <h2 key="h3">{t('mobileTestSolution')}</h2>
          <h1 key="h2">
            <Trans i18nKey="smartMobileAppAutomatedTesting">
              <br />
            </Trans>
          </h1>
          <span key="button">
            <Button
              size="large"
              type="primary"
              onClick={handleGetStarted}
              style={{ marginTop: 20 }}
            >
              {t('getStarted')}
            </Button>
          </span>
        </QueueAnim>
      </div>
    </Element>
  );
}

Banner.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  navToShadow: PropTypes.func,
};

Banner.defaultProps = {
  className: 'banner',
};

export default Banner;
