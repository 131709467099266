import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HUMANCREST_CONTACT_US } from 'data/urls';
import { Link } from '@reach/router';

function Footer({ isMobile }) {
  const { t } = useTranslation();
  return (
    <footer id="footer" className="dark">
      <div className="footer-wrap">
        <Row>
          <Col lg={16} sm={24} xs={24} />
          <Col lg={8} sm={24} xs={24}>
            <div className="footer-center">
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/moppium"
                >
                  Twitter
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/moppium"
                >
                  Facebook
                </a>
              </div>
              <div>
                <span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={HUMANCREST_CONTACT_US}
                  >
                    {t('contactUs')}
                  </a>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="bottom-bar">
        <Col lg={6} sm={24} />
        <Col lg={18} sm={24}>
          <span
            style={{
              lineHeight: '16px',
              paddingRight: 12,
              marginRight: 11,
              borderRight: '1px solid rgba(255, 255, 255, 0.55)',
            }}
          >
            <Link to="terms">{t('termOfService')}</Link>
          </span>
          <span style={{ marginRight: 24 }}>
            <Link to="privacy">{t('privacyPolicy')}</Link>
          </span>
          {isMobile && <br />}
          <span style={{ marginRight: 12 }}>
            Copyright © 2016-2020 Human Crest Co., Ltd.
          </span>
        </Col>
      </Row>
    </footer>
  );
}

Footer.propTypes = {
  isMobile: PropTypes.bool,
};

export default Footer;
