import React, { useEffect, useState, useCallback } from 'react';
import { enquireScreen } from 'enquire-js';
import SEO from 'components/SEO';
import { useI18next } from 'gatsby-plugin-react-i18next';
import ContactUs from 'components/ContactUs';
import Header from './Header';
import Banner from './Banner';
import Page2 from './Page2';
import Page3 from './Page3';
import Footer from './Footer';
import './static/style';

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const { t } = useI18next();

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(!!b);
    });
  }, []);

  const navToShadow = useCallback((e) => {
    setShowShadow(e.mode === 'leave');
  }, []);

  return (
    <div>
      <SEO title={t('home')} description={t('SEODescription')} />
      <Header
        key="header"
        className={showShadow ? 'show-shadow' : ''}
        style={{ position: 'fixed' }}
      />
      <Banner key="banner" isMobile={isMobile} navToShadow={navToShadow} />
      <Page3 key="page3" />
      <Page2 key="page2" />
      <Footer key="footer" isMobile={isMobile} />
      <ContactUs isMobile={isMobile} fixed />
    </div>
  );
}

export default Home;
