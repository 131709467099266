import React from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Descriptions } from 'antd';

export default function Page2() {
  const { t } = useTranslation();

  return (
    <OverPack component="section" className="page-wrapper page2">
      <QueueAnim
        type="bottom"
        className="page text-center"
        leaveReverse
        key="page"
      >
        <h2 key="title">{t('targetEnvironment')}</h2>
        <span key="line" className="separator" />
        <QueueAnim type="bottom" className="info-content" key="content">
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
          >
            <Descriptions.Item label="PC OS">Mac OS X</Descriptions.Item>
            <Descriptions.Item label={null}>
              10.13.6{t('andUp')}
            </Descriptions.Item>
            <Descriptions.Item label="iOS">
              {t('simulatorAndRealDevices')}
            </Descriptions.Item>
            <Descriptions.Item label={null}>10.3.1~13.5</Descriptions.Item>
            <Descriptions.Item label="Android">
              {t('emulatorAndRealDevices')}
            </Descriptions.Item>
            <Descriptions.Item label={null}>4.3{t('andUp')}</Descriptions.Item>
          </Descriptions>
        </QueueAnim>
      </QueueAnim>
    </OverPack>
  );
}
